<template>
  <div class="page">
     <headerPreview v-if="is_preview"></headerPreview>
    <Headers v-else ></Headers>
    <div class="con">
      <div class="suggest">
        <el-card class="box-card">
          <el-table
            :data="tableData"
            style="width: 1200px; margin-bottom: 0px"
            row-key="id"
            border
            class="custom-table1"
            :row-class-name="tableRowClassName"
          >
            <!-- <el-table-column label="序号" min-width="50" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="width: 100%; text-align: center">
                  {{ scope.$index + 1 }}
                </div>
              </template>
            </el-table-column> -->

            <el-table-column
              prop="cname"
              align="center"
              label="城市"
              min-width="60"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
            v-if="level_name !=='B'"
              prop="qname"
              align="center"
              label="区县市"
              min-width="60"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="block"
              align="center"
              label="板块/街道"
              min-width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="locationname"
              align="center"
              label="地块名称"
              min-width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="location"
              align="center"
              label="地块位置"
              min-width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="acreagemu"
              align="center"
              label="面积（亩）"
              min-width="70"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="useyears"
              align="center"
              label="出让年限"
              min-width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="usedetail"
              align="center"
              label="规划用途"
              min-width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="volume"
              align="center"
              label="容积率"
              min-width="60"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="height"
              align="center"
              label="建筑高度"
              min-width="66"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="green"
              align="center"
              label="绿地率"
              min-width="60"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="status_txt"
              align="center"
              label="当前状态"
              min-width="80"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>

          <div slot="header" class="clearfix">
            <div
              style="
                font-size: 18px;
                width: 100%;
                text-align: center;
                font-weight: 600;
              "
            >
              {{ tableData[0].locationname }}
            </div>
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
          <div
            style="
              font-size: 18px;
              width: 100%;
              text-align: center;
              margin-top: 20px;
              margin-bottom: 30px;
              font-weight: 500;
              font-weight: 600;
            "
          >
            房企意向调查表
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="160px"
          >
            <div style="width: 94%; margin-left: 3%">
              <span style="font-size: 16px; font-weight: bold;color:#666"
                >地块信息方面</span
              >
              <el-divider></el-divider>
            </div>

            <el-form-item label="地块意向度:" prop="intention">
              <el-radio-group v-model="ruleForm.intention">
                <el-radio
                  class="radio"
                  v-for="item in intentionList"
                  :label="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="建议容积率:" prop="ratio">
              <el-radio-group v-model="ruleForm.ratio">
                <el-radio
                  class="radio"
                  v-for="item in ratioList"
                  :label="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
              <el-input
                style="width: 300px; margin-left: 40px"
                placeholder="其他"
                v-model="ruleForm.ratioOther"
              ></el-input>
            </el-form-item>
            <el-form-item label="外部环境关注点:" prop="watch">
              <el-checkbox-group v-model="ruleForm.watch">
                <el-checkbox
                  class="checkbox"
                  v-for="(item, index) in watchlist"
                  :key="item.id"
                  :label="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="" prop="watchOther">
              <el-input
                style="width: 600px;margin-left:20px"
                placeholder="对外部环境其他关注点的建议"
                v-model="ruleForm.watchOther"
              ></el-input>
            </el-form-item>

            <el-form-item label="内部规划要点:" class="innerlayout" prop="innerlayout">
              <el-checkbox-group v-model="ruleForm.innerlayout">
                <el-checkbox
                  name="innerlayout"
                  class="checkbox"
                  v-for="item in innerlayoutlist"
                  :key="item.id"
                  :label="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="">
              <el-input
                style="width: 600px;margin-left:20px"
                placeholder="对内部规划其他要点的建议"
                v-model="ruleForm.innerlayoutOther"
              ></el-input>
            </el-form-item>
            <div style="width: 94%; margin-left: 3%">
              <span style="font-size: 16px; font-weight: bold;color:#666"
                >企业意向单位方面</span
              >
              <el-divider></el-divider>
            </div>

            <el-form-item label="意向拿地模式:" prop="companyidea">
              <div class="inndexcate">
                <div
                  class="caterow"
                  v-for="(company, index) in companyideaList"
                  :key="company.pid"
                >
                  <div class="catename">{{ company.cate }}</div>
                  <el-checkbox-group
                    v-model="ruleForm.companyidea"
                    class="radio-vertical"
                  >
                    <el-checkbox
                      name="companyidea"
                      class="radiosingle radio"
                      v-for="item in company.list"
                      :key="item.id"
                      :label="item.id"
                      >{{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="">
              <el-input
                style="width: 600px;margin-left:20px"
                placeholder="其他企业意向拿地模式"
                v-model="ruleForm.companyideaOther"
              ></el-input>
            </el-form-item>

            <el-form-item label="股权合作出资比例:" prop="invest">
              <el-radio-group v-model="ruleForm.invest">
                <el-radio
                  class="radio"
                  v-for="item in investList"
                  :label="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>

            <el-form-item label="其他意见或建议:" prop="otherAdvice">
              <el-input
                type="textarea"
                style="height: 180px; width: 800px"
                v-model="ruleForm.otherAdvice"
              ></el-input>
            </el-form-item>
            <el-form-item class="btnbox">
              <el-button class="btntijiao" type="primary" @click="onSubmit"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  LoginedlandDetail,
  saveSuggest,
  surveyOptions,
} from "@/api/website/collect.js";

import Headers from '@/components/collect/header'
import headerPreview from '@/components/collect/headerPreview'
export default {
  components:{Headers,headerPreview},
  props: {
      is_preview: 0
    },
  data() {
    return {
      level_name:'',
      city_id: null,
      id: null,
      tableData: [
        {
          locationname: "",
        },
      ],

      //股权合作出资比例条件
      investList: [
        {
          id: 1,
          name: "不出资",
        },
        {
          id: 2,
          name: "出资5-10%",
        },
        {
          id: 3,
          name: "出资10-20%",
        },
        {
          id: 4,
          name: "出资20-50%",
        },
        {
          id: 5,
          name: "出资50%以上",
        },
      ],
      //意向度条件
      intentionList: [
        {
          id: 1,
          name: "高",
        },
        {
          id: 2,
          name: "较高",
        },
        {
          id: 3,
          name: "一般",
        },
        {
          id: 4,
          name: "无",
        },
      ],
      //容积率条件
      ratioList: [
        {
          id: 1,
          name: "1.0以下",
        },
        {
          id: 2,
          name: "1.0＜r≤1.3",
        },
        {
          id: 3,
          name: "1.3＜r≤1.6",
        },
        {
          id: 4,
          name: "1.6以上",
        },
      ],
      //外部关注点条件
      watchlist: [
        {
          id: 1,
          name: "政策环境",
        },
        {
          id: 2,
          name: "土地市场情况",
        },
        {
          id: 3,
          name: "新房市场情况",
        },
        {
          id: 4,
          name: "学区配套",
        },
        {
          id: 5,
          name: "其他配套资源",
        },
        {
          id: 6,
          name: "板块未来规划",
        },
        {
          id: 7,
          name: "融资环境",
        },
        {
          id: 8,
          name: "资金监管情况",
        },
      ],
      //内部关注点条件
      innerlayoutlist: [
        {
          id: 1,
          name: "地块体量",
        },
        {
          id: 2,
          name: "容积率",
        },
        {
          id: 3,
          name: "限高/层高",
        },
        {
          id: 4,
          name: "产品形态",
        },
        {
          id: 5,
          name: "配建要求",
        },
        {
          id: 6,
          name: "装修要求",
        },
        {
          id: 7,
          name: "户型面积要求",
        },
        {
          id: 8,
          name: "绿色建筑星级",
        },
        {
          id: 9,
          name: "地块上市时间",
        },
      ],
      //公司拿地意向条件
      companyideaList: [
        {
          pid: 1,
          cate: "全资分类",
          list: [
            {
              id: 1,
              name: "全资拿地（独立操盘）",
            },
          ],
        },
        {
          pid: 2,
          cate: "股权合作",
          list: [
            {
              id: 2,
              name: "大股操盘",
            },
            {
              id: 3,
              name: "小股操盘",
            },
            {
              id: 4,
              name: "联合操盘",
            },
          ],
        },
        {
          pid: 3,
          cate: "代建代销",
          list: [
            {
              id: 5,
              name: "融资代建",
            },
            {
              id: 6,
              name: "纯代建",
            },
            {
              id: 7,
              name: "咨询服务",
            },
          ],
        },
        {
          pid: 4,
          cate: "联合代建",
          list: [
            {
              id: 8,
              name: "政企联合代建",
            },
            {
              id: 9,
              name: "房企联合代建",
            },
          ],
        },
      ],

      ruleForm: {
        intention: 1, //意向度
        ratio: 3, //容积率
        watch: [], //外部关注点
        watchOther: "", //外部关注其他
        innerlayout: [], //内部关注点
        innerlayoutOther: "", //外部关注点其他
        companyidea: [], //公司拿地意向
        companyideaOther: "", //公司拿地意向其他
        invest: 5, //股权合作出资比例
        otherAdvice: "", //其他建议
      },
      rules: {
        intention: [
          { required: true, message: "请选择意向度", trigger: "change" },
        ],
        ratio: [{ required: true, message: "请选择容积率", trigger: "change" }],
        watch: [
          { required: true, message: "请选择外部关注点", trigger: "change" },
        ],

        innerlayout: [
          { required: true, message: "请选择内部关注点", trigger: "change" },
        ],

        companyidea: [
          { required: true, message: "请选择公司拿地意向", trigger: "change" },
        ],

        invest: [
          {
            required: true,
            message: "请选择股权合作出资比例",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
    surveyOptions({ city_id: this.city_id, did: this.id }).then((res) => {
      this.intentionList = res.data.intentionList;
      this.ratioList = res.data.ratioList;
      this.watchList = res.data.watchList;
      this.innerlayoutList = res.data.innerlayoutList;
      this.companyideaList = res.data.companyideaListParent;
      this.investList = res.data.investList;
    });
    this.getlandDetail();
  },
  created() {
    this.id = this.$route.params.id;
    this.city_id = this.$route.params.cityid;
  },
  methods: {
    tableRowClassName() {
      return "pointer-row"; // 为每一行添加自定义类名
    },

    getlandDetail() {
      LoginedlandDetail({ id: this.id,city_id: this.city_id}).then((res) => {
        this.tableData = [res.data.table];
        this.level_name = res.data.level_name;
      });
    },
    onSubmit() {
      let params = this.ruleForm;
      // params.watch = JSON.stringify(params.watch);
      // params.innerlayout = JSON.stringify(params.innerlayout);
      // params.companyidea = JSON.stringify(params.companyidea);

      params.did = this.id;
      params.city_id = this.city_id;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {


             params.watch = JSON.stringify(params.watch);
            params.innerlayout = JSON.stringify(params.innerlayout);
            params.companyidea = JSON.stringify(params.companyidea);


          console.log("params", params);

          saveSuggest(params).then((res) => {
            if (res.code === 1) {
              this.$message.success("提交成功");
            }

            this.$router.go(-1);
          });

          // this.$router.push('/website/collect/success')
        } else {
          this.$message.info("请按照提示将表单选择完整后提交");
          return false;
        }
      });
    },
    resetForm() {},
  },
};
</script>
<style lang="scss" scoped>
.btnbox {
  display: flex;
}
.btntijiao {
  background: #009d70;
  margin-left: 280px;
  width: 200px;
}

.custom-table1 {
  ::v-deep .el-table__header-wrapper th {
    background-color: #009d70; // 设置表头颜色
    color: #ffffff; // 设置表头文字颜色
  }

  ::v-deep .el-table__header-wrapper .cell {
    padding-left: 0;
    padding-right: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
  }

  ::v-deep .el-table__body-wrapper tr:hover {
    // cursor: pointer; /* 设置鼠标样式为 pointer */
  }
  ::v-deep .el-table__body-wrapper .cell {
    padding-left: 0;
    padding-right: 0;
    font-size: 15px;
  }
  ::v-deep .el-table__body-wrapper .el-table__cell {
    padding: 10px 2px;
  }
}

.con {
  margin-top: 30px;
  margin-bottom: 120px;
}

.page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .con {
    display: flex;
    justify-content: center;
  }
  .suggest {
    text-align: left;
    width: 1200px;
  }
  .inndexcate {
    display: flex;
    .caterow {
      width: 17%;
      border-right:solid 1px #ddd;
      padding-left: 50px;
    }
    .caterow:last-child{
      border-right:none;
    }
    
  }

  .catename {
    margin-left: 0px;
    font-size: 14px;
    font-weight: bold;
    height: 50px;
    margin-left:20px;
  }
  .radio-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 可以根据需要调整对齐方式 */
    .radiosingle {
      height: 30px;
    }
  }
  .radio {
    width: 90px;
  }
  .checkbox {
    width: 90px;
  }
}

::v-deep  .el-table__body-wrapper .cell{
  font-weight:bold;
  font-size:14px;
}

::v-deep .el-form-item__label{
  font-weight: bold;
  color:#000;
  margin-right:20px;
}

::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before{
  font-size: 20px;
  position: relative;
  top:5px;
}
::v-deep .el-textarea .el-textarea__inner{
  height:140px;
  width:600px;
}

 .innerlayout ::v-deep .checkbox:last-child{
  margin-left:20px;
}

 ::v-deep  .el-table__body-wrapper .el-table__cell{
  color:#000;
 }


 
 ::v-deep  .el-table__body-wrapper .el-table__cell{
  color:#000;
  padding:10px 2px;
  font-size:15px;
 }
 /*鼠标移入某行时的背景色*/
.el-table__row  tr:hover > td {
   background-color: #92cbf1;
}



 ::v-deep .el-table .el-table__cell{
  padding:4px  0;
 }

</style>